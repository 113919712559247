<template>
  <div class="card">

    <div class="poster">
      <img src="../../assets/youshi/002.png" alt="全链路可追溯"/>
    </div>

    <div class="details">
      <h2>
        物流管理
        <br />
        <span><router-link to="/about" style="color: black;text-decoration: none">点击此处，了解更多</router-link></span>
      </h2>

      <div class="rating">
        <span>9.9/10</span>
      </div>

      <div class="tags">
        <span class="tag">对接物流平台</span>
        <span class="tag">一键下单</span>
        <span class="tag">物流跟踪</span>
      </div>

      <div class="info">
        <p>
          1.系统已对接跨越、顺丰平台<br />
          2.实时跟踪物流信息<br />
          3.一键下单，生成快递单

        </p>
      </div>

      <div class="cast">
        <h4>优势</h4>

        <ul>
<!--          <li><img src="../../assets/youshi/0-01.png"  /></li>-->
<!--          <li><img src="../../assets/youshi/0-02.png" /></li>-->
<!--          <li><img src="../../assets/youshi/0-03.png" /></li>-->
          <li>
            <el-popover
                placement="bottom" trigger="hover" title="一键下单" content="系统根据配置物流平台信息和出库需求指定的物流、时效、收货方信息，一键下单创建第三方快递运单
并支持根据收货方客户并单发货" z-index="99">
              <img src="../../assets/youshi/0-01.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>

          <li>
            <el-popover
                placement="bottom" trigger="hover" title="打印面单" content="通过系统直接下单的出库单可直接在系统内打印运单，包括自定义面单、快递面单" z-index="99">
              <img src="../../assets/youshi/0-02.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>

          <li>
            <el-popover
                placement="bottom" trigger="hover" title="物流跟踪" content="通过系统下单或人工维护了物流单号信息的出库单，系统实时更新运单状态，直至签收" z-index="99">
              <img src="../../assets/youshi/0-03.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>
        </ul>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  data(){
    return{

    }
  }
}
</script>

<style scoped>


.card {
  position: relative;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 450px;
  background-color: #404359;
  overflow: hidden;
  box-shadow: 1px 3px 20px 10px #eaeaea;
  border-radius: 10px;
  float: left;
}

.card .poster {
  position: relative;
  overflow: hidden;
}

.card .poster::before {
  content: '';
  position: relative;
  bottom: -200px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 50%, transparent);
  transition: .5s;
  z-index: 1;
}

.card:hover .poster::before {
  bottom: 0;
}

.card .poster img {
  width: 100%;
  transition: .5s;
}

.card:hover .poster img {
  filter: blur(3px);
  transform: translateY(-50px);
}

.details {
  position: absolute;
  margin: 5%;
  width: 91.5%;
  height: 77%;
  bottom: -200px;
  left: 0;
  transition: .5s;
  z-index: 2;
}

.card:hover .details {
  bottom: 0;
}

.details h2 {
  color: #000;
  font-size: 20px;
  font-weight: bolder;
}

.details h2 span:first-child {
  color: rgba(255, 255, 255, .7);
  font-size: 18px;
}

.details h2 span:last-child {
  font-size: 12px;
  color: #000;
  display: inline-block;
  background-color: #f0b778;
  width: 130px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 0 0 2px;
  border-radius: 2px;
}

.rating {
  position: relative;
  padding: 5px 0;
}

.rating span {
  color: rgb(127,127,127);
  /*text-shadow: 0 0 3px #eaeaea;*/
  padding: 0 5px;
  font-weight: bolder;
}

.tags {
  position: relative;
  margin-top: 5px;
}

.tags span {
  padding: 2px 5px;
  margin-right: 2px;
  color: #FFF;
  display: inline-block;
  border-radius: 4px;
}

.tags .tag {
  background-color: #2b2c31;
}

.info {
  color: #FFF;
}

.info p {
  margin: 15px 0 10px;
  font-size: 15px;
}

.cast {
  position: relative;
  margin-top: -15px;
}

.cast h4 {
  font-size: 20px;
  color: #1ac040;
  letter-spacing: 1px;
}

.cast ul {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cast ul li {
  list-style: none;
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
  border: 2px solid #FFF;
  box-shadow: 0 0 6px #FFF;
}

.cast ul li img {
  width: 100%;
}
</style>
