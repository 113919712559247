<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <youshi1 style="left: 50%"></youshi1>
      </div>
      <div class="swiper-slide">
        <youshi2 style="left: 50%"></youshi2>
      </div>
      <div class="swiper-slide">
        <youshi3 style="left: 50%"></youshi3>
      </div>
      <div class="swiper-slide">
        <youshi4 style="left: 50%"></youshi4>
      </div>
      <div class="swiper-slide">
        <youshi5 style="left: 50%"></youshi5>
      </div>
      <div class="swiper-slide">
        <youshi6 style="left: 50%"></youshi6>
      </div>
    </div>
    <!-- 分页器 -->
    <!--    <div class="swiper-pagination"></div>-->

    <!-- 如果需要导航按钮 -->
    <!--    <div class="swiper-button-prev"></div>-->
    <!--    <div class="swiper-button-next"></div>-->

    <!-- 滚动条 -->
    <!--    <div class="swiper-scrollbar"></div>-->
  </div>
</template>

<script>
// 可以直接在组件里引入这个文件
import Swiper from 'swiper';

//引入css
import 'swiper/css/swiper.min.css';

import youshi1 from './YouShi/YouShi1.vue'
import youshi2 from './YouShi/YouShi2.vue'
import youshi3 from './YouShi/YouShi3.vue'
import youshi4 from './YouShi/YouShi4.vue'
import youshi5 from './YouShi/YouShi5.vue'
import youshi6 from './YouShi/YouShi6.vue'

export default {
  components: {
    youshi1,
    youshi2,
    youshi3,
    youshi4,
    youshi5,
    youshi6,
  },
  mounted() {
    new Swiper ('.swiper-container', {
      // direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      //自动切换
      autoplay:{
        pauseOnMouseEnter: true,
      },

      //每行显示3个
      slidesPerView: 3,

      // effect: 'coverflow',



      disableOnInteraction: false,

      // 如果需要滚动条
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
    })
  },
}
</script>

<style>
.swiper-container {
  margin-top: 10%;
  width: 100%;
  height: 700px;
}
</style>
