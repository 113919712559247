<template>
  <div class="card">

    <div class="poster">
      <img src="../../assets/youshi/005.png" alt="稳定可靠"/>
    </div>

    <div class="details">
      <h2>
        稳定可靠
        <br />
        <span><router-link to="/about" style="color: black;text-decoration: none">点击此处，了解更多</router-link></span>
      </h2>

      <div class="rating">
        <span>9.9/10</span>
      </div>

      <div class="tags">
        <span class="tag">数据安全</span>
        <span class="tag">部署安全</span>
        <span class="tag">网络安全</span>
      </div>

      <div class="info">
        <p>
          1.超强技术团队支撑<br />
          2.系统运行安全稳定<br />
          3.数据安全可靠

        </p>
      </div>

      <div class="cast">
        <h4>优势</h4>

        <ul>
<!--          <li><img src="../../assets/youshi/0-01.png"  /></li>-->
<!--          <li><img src="../../assets/youshi/0-02.png" /></li>-->
<!--          <li><img src="../../assets/youshi/0-03.png" /></li>-->
<!--          <li><img src="../../assets/youshi/0-04.png" /></li>-->
          <li>
            <el-popover
                placement="bottom" trigger="hover" title="数据访问安全" content="通过防止注入、拦截恶意请求访问，保证数据的可靠性、安全性" z-index="99">
              <img src="../../assets/youshi/0-01.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>

          <li>
            <el-popover
                placement="bottom" trigger="hover" title="数据存储安全" content="独立数据库，支持物理隔离、逻辑隔离，数据实时备份" z-index="99">
              <img src="../../assets/youshi/0-02.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>

          <li>
            <el-popover
                placement="bottom" trigger="hover" title="部署稳定安全" content="通过多重负载、热部署保证系统稳定，实时监控风控系统，拦截恶意请求、IP" z-index="99">
              <img src="../../assets/youshi/0-03.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>

          <li>
            <el-popover
                placement="bottom" trigger="hover" title="网络安全" content="采用SSL加密和非对称加密算法保证数据传输安全" z-index="99">
              <img src="../../assets/youshi/0-04.png"  slot="reference" style="width: 100%;height: 100%;"/>
            </el-popover>
          </li>
        </ul>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  data(){
    return{

    }
  }
}
</script>

<style scoped>

/*.container {*/
/*  width: 100%;*/
/*  height: 100%;*/

/*}*/

/*.container::before{*/
/*  background-image: url('@/assets/test/background.jpg');*/

/*  background-size: cover;*/

/*  width: 100%;*/

/*  height: 700px;*/

/*  content: "";*/

/*  opacity: 0.5;*/

/*  position: absolute;*/

/*  !*z-index: -1;!*-1 可以当背景*!*!*/

/*  filter: blur(10px);*/

/*}*/


/*.container:before {*/
/*  !*content: "";*!*/
/*  !*width: 600px;*!*/
/*  !*height: 700px;*!*/
/*  !*!* background-color: #F70661; *!*!*/
/*  !*background-image: linear-gradient(270deg, rgba(17, 19, 25, 0) 0%, rgba(17, 19, 25, 0.05) 16%, rgba(17, 19, 25, 0.2) 30%, rgba(17, 19, 25, 0.39) 43%, rgba(17, 19, 25, 0.61) 55%, rgba(17, 19, 25, 0.8) 68%, rgba(17, 19, 25, 0.95) 82%, rgb(17, 19, 25) 98%);*!*/
/*  !*right: 511px;*!*/
/*  !*top: 0;*!*/

/*}*/

/*.container::after {*/
/*  content: "";*/
/*  width: 1109px;*/
/*  height: 200px;*/
/*  !* background-color: #F70661; *!*/
/*  background-image: linear-gradient(180deg, rgba(17, 19, 25, 0) 0%, rgba(17, 19, 25, 0.05) 16%, rgba(17, 19, 25, 0.2) 30%, rgba(17, 19, 25, 0.39) 43%, rgba(17, 19, 25, 0.61) 55%, rgba(17, 19, 25, 0.8) 68%, rgba(17, 19, 25, 0.95) 82%, rgb(17, 19, 25) 98%);*/
/*  right: 0px;*/
/*  bottom: 0;*/
/*}*/

.card {
  position: relative;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 450px;
  background-color: #404359;
  overflow: hidden;
  box-shadow: 1px 3px 20px 10px #eaeaea;
  border-radius: 10px;
  float: left;
}

.card .poster {
  position: relative;
  overflow: hidden;
}

.card .poster::before {
  content: '';
  position: relative;
  bottom: -200px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 50%, transparent);
  transition: .5s;
  z-index: 1;
}

.card:hover .poster::before {
  bottom: 0;
}

.card .poster img {
  width: 100%;
  transition: .5s;
}

.card:hover .poster img {
  filter: blur(3px);
  transform: translateY(-50px);
}

.details {
  position: absolute;
  margin: 5%;
  width: 91.5%;
  height: 77%;
  bottom: -200px;
  left: 0;
  transition: .5s;
  z-index: 2;
}

.card:hover .details {
  bottom: 0;
}

.details h2 {
  color: #000;
  font-size: 20px;
  font-weight: bolder;
}

.details h2 span:first-child {
  color: rgba(255, 255, 255, .7);
  font-size: 18px;
}

.details h2 span:last-child {
  font-size: 12px;
  color: #000;
  display: inline-block;
  background-color: #f0b778;
  width: 130px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 0 0 2px;
  border-radius: 2px;
}

.rating {
  position: relative;
  padding: 5px 0;
}

.rating span {
  color: rgb(127,127,127);
  /*text-shadow: 0 0 3px #eaeaea;*/
  padding: 0 5px;
  font-weight: bolder;
}

.tags {
  position: relative;
  margin-top: 5px;
}

.tags span {
  padding: 2px 5px;
  margin-right: 2px;
  color: #FFF;
  display: inline-block;
  border-radius: 4px;
}

.tags .tag {
  background-color: #2b2c31;
}

.info {
  color: #FFF;
}

.info p {
  margin: 15px 0 10px;
  font-size: 15px;
}

.cast {
  position: relative;
  margin-top: -15px;
}

.cast h4 {
  font-size: 20px;
  color: #1ac040;
  letter-spacing: 1px;
}

.cast ul {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cast ul li {
  list-style: none;
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
  border: 2px solid #FFF;
  box-shadow: 0 0 6px #FFF;
}

.cast ul li img {
  width: 100%;
}
</style>
