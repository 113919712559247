<template>
  <div class="app" style="overflow: auto">

    <!-- 导航区域 -->
    <div class="navs">

<!--      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" style="font-size: 23px">-->
<!--        <img src="../assets/logo/xjt.png" style="width: 200px;height: 100%;margin-left: -1100px">-->
<!--        <el-menu-item index="1" @click="getAnchor('#首页')" style="margin-left: 35%"  class="title">首页</el-menu-item>-->
<!--        <el-menu-item index="2" @click="getAnchor('#团队介绍')" class="title">团队介绍</el-menu-item>-->
<!--        <el-menu-item index="3" @click="getAnchor('#服务优势')" class="title">服务优势</el-menu-item>-->
<!--        <el-menu-item index="4" @click="getAnchor('#通关服务')" class="title">通关服务</el-menu-item>-->
<!--        <el-menu-item index="5" @click="getAnchor('#物流服务')" class="title">物流服务</el-menu-item>-->
<!--        <el-menu-item index="6" @click="getAnchor('#核心优势')" class="title">核心优势</el-menu-item>-->
<!--        <button class="card1-two-2-button1" @click="drawer = true" style="margin-top: 10px">加入合作</button>-->
<!--      </el-menu>-->
      <div style="width:200px;height:60px;display:table-cell;">
        <img src="../assets/logo/xjt.png" style="max-height:100%;max-width:100%;">
      </div>

      <div>
        <ul>
          <li @click="getAnchor('#首页')">首页</li>
          <li @click="getAnchor('#团队介绍')">团队介绍</li>
          <li @click="getAnchor('#服务优势')">服务优势</li>
          <li @click="getAnchor('#通关服务')">通关服务</li>
          <li @click="getAnchor('#物流服务')">物流服务</li>
          <li @click="getAnchor('#核心优势')">核心优势</li>
        </ul>
      </div>

      <div>
        <button class="card1-two-2-button1" @click="drawer = true">加入合作</button>
      </div>

    </div>

    <!-- 内容区域 -->
    <div class="content">
<!--      <div style="height: 100px;width: 100%;background: red">nihao</div>-->
      <div id="首页" class="content-div">
<!--        <video playsinline autoplay="autoplay" muted="muted" loop="loop" fluid="true" style="height: 100%;width: 100%;z-index: 0">-->
<!--          <source src="https://xjt-picture-video.oss-cn-beijing.aliyuncs.com/picture/PopPick2021.mp4" type="video/mp4" >-->
<!--        </video>-->

        <page-lun-bo></page-lun-bo>
      </div>

      <div id="团队介绍" class="content-div1">
        <div class="content-div1-card">
          <button @click="getAnchor('#团队介绍')" type="button"><i class="el-icon-caret-top"></i></button>
          <div>
            团队介绍
          </div>
        </div>

        <div class="page2-box">
          <img src="../assets/img-p2/tu1.png" class="page2-tu">

          <div class="page2-content">
            <img src="../assets/img-p2/tu2.png" style="height: 80%;width: 20%">
            <div style="width: 70%;height: 100%">
              <p style="font-size: 40px;font-weight: bolder;margin-top: 5%">团队能力</p>
              <ul>
                <li>高素质专业的管理人才队伍，具备优秀的方案解决、设计与执行能力。</li>
                <li>团队硕士及以上学历占比5%，本科学历占比70%，大专学历占比25%。</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="page2-box">
          <div class="page2-content">

            <div style="width: 70%;height: 100%">
              <p style="font-size: 40px;font-weight: bolder;margin-top: 5%;text-align: right">团队经验</p>
              <ul>
                <li>团队成员均有与10+著名企业战略合作的经验 ，主要服务电子行业上下游、电商平台及第三方物流。行业经验丰富，熟悉产品特性及操作流程。</li>
              </ul>
            </div>

            <img src="../assets/img-p2/tu3.png" style="height: 80%;width: 20%">

          </div>

          <img src="../assets/img-p2/tu4.png" class="page2-tu">
        </div>

      </div>

      <div id="服务优势" class="content-div2">

        <div class="content-div2-card">
          <button @click="getAnchor('#服务优势')" type="button"><i class="el-icon-caret-top"></i></button>
          <div>服务优势</div>
        </div>

        <div style="width: 40%;height: 100%;float: left;display: flex;align-items: center;justify-content: space-around">
          <div style="width: 80%;height: 80%;display: flex;align-items: center;justify-content: space-around">
            <img src="../assets/img-p3/tu1.png">
          </div>
        </div>

        <div style="width: 60%;height: 100%;float: left">
          <ul style="margin-top: 50px;list-style-type: none">

            <li class="li">
              <div style="width: 10%;height: 100%;float: left">
                <img src="../assets/img-p3/tu2.png" style="width: 70px;">
              </div>
              <div style="width: 90%;height: 100%;float: left">
                <span class="p3-title-ziti">协同平台多角色</span>
                <br/>
                <p>供应链物流场景全链路协同管理，涵盖订单、仓储、运输、进出口货运代理等业务场录的作业管理。企业与客户、供应商、业务关联方等多方跨组织、跨系统的协同作业。</p>
              </div>
            </li>

            <li class="li">
              <div style="width: 10%;height: 100%;float: left">
                <img src="../assets/img-p3/tu4.png" style="width: 70px;">
              </div>
              <div style="width: 90%;height: 100%;float: left">
                <span class="p3-title-ziti">智能系统预警</span>
                <br/>
                <p>库存预警/风险预警/异常预警，依客户需求制定多维度预警机制。</p>
              </div>
            </li>

            <li class="li">
              <div style="width: 10%;height: 100%;float: left">
                <img src="../assets/img-p3/tu3.png" style="width: 70px;">
              </div>
              <div style="width: 90%;height: 100%;float: left">
                <span class="p3-title-ziti">降低供应链成本</span>
                <br/>
                <p>一体化服务，减少流通环节，降低流通成本；依托系统平台，降低人工成本。</p>
              </div>
            </li>

            <li class="li">
              <div style="width: 10%;height: 100%;float: left">
                <img src="../assets/img-p3/tu5.png" style="width: 70px;">
              </div>
              <div style="width: 90%;height: 100%;float: left">
                <span class="p3-title-ziti">优化执行效率</span>
                <br/>
                <p>一体化服务，减少流通环节，提高流通效率；依托系统平台，提高运营效率。</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div id="通关服务" class="content-div4">

        <div class="content-div3-card">
          <button @click="getAnchor('#通关服务')" type="button"><i class="el-icon-caret-top"></i></button>
          <div>通关服务</div>
        </div>

        <div class="page4-div">
          <div class="page4-div-1">
            <img src="../assets/img-p5/tu1.png">
          </div>
        </div>

        <div class="page4-div-fu">
          <el-card class="page4-div-card">
            <el-card shadow="hover" class="page4-div-card-all page4-div-card-all-1">
              <div style="width: 70%;height: 100%;float: left">覆盖全国口岸</div>
              <div style="width: 30%;height: 100%;float: left">
                <img src="../assets/img-p5/ditu.png" style="width: 80px;height: 80px;margin-top: -10px;margin-left: 15px">
              </div>
            </el-card>

            <el-card shadow="hover" class="page4-div-card-all page4-div-card-all-2">
              <div style="width: 70%;height: 100%;float: left;margin-top: -25px">
                <span style="font-size: 18px;font-weight: normal">全方位服务</span>
                <br />
                <span>365天24小时</span>
              </div>
              <div style="width: 30%;height: 100%;float: left">
                <img src="../assets/img-p5/shijian.png" style="width: 80px;height: 80px;margin-top: -10px;margin-left: 15px">
              </div>
            </el-card>

            <el-card shadow="hover" class="page4-div-card-all page4-div-card-all-3">
              <div style="width: 70%;height: 100%;float: left">监管证件代办</div>
              <div style="width: 30%;height: 100%;float: left">
                <img src="../assets/img-p5/tu2.svg" style="width: 80px;height: 80px;margin-top: -10px;margin-left: 15px">
              </div>
            </el-card>

            <el-card shadow="hover" class="page4-div-card-all page4-div-card-all-4">
              <div style="width: 33.3%;height: 100%;float: left">报关</div>
              <div style="width: 33.3%;height: 100%;float: left">
                <img src="../assets/img-p5/haiguan.png" style="width: 80px;height: 80px;margin-top: -10px;margin-left: 15px">
              </div>
              <div style="width: 33.3%;height: 100%;float: left;">
                <span style="float: right">报检</span>
              </div>
            </el-card>
          </el-card>
        </div>
      </div>

      <div id="物流服务" class="content-div3">

        <div class="content-div4-card">
          <button @click="getAnchor('#物流服务')" type="button"><i class="el-icon-caret-top"></i></button>
          <div>物流服务</div>
        </div>

        <div style="width: 100%;height: 28.3%;display: flex;justify-content: space-around">

          <el-card class="page5-box" style="box-shadow: 0 0 20px #000;margin-top: 15px">
            <p style="color: rgb(0,112,192);font-weight: bolder;font-size: 25px;text-align: center">中港运输服务</p>
            <ul>
              <li class="page5-box-li">丰富的中港运输经验，涉及普货、危险品、温控等货物运输，为客户提供个性化中港运输方案；</li>
              <li class="page5-box-li">覆盖珠三角、大湾区，深圳各陆运口岸（深圳湾/皇崗/文錦渡/沙頭角）可提供报关、转关服务；深圳各大保税区间中港，国内运输服务。</li>
            </ul>
          </el-card>

          <div class="page5-box">

          </div>

          <el-card class="page5-box" style="box-shadow: 0 0 20px #000;margin-top: 15px">
            <p style="color: rgb(0,112,192);font-weight: bolder;font-size: 25px;text-align: center">国际物流服务</p>
            <ul>
              <li class="page5-box-li">提供全球一站式通关物流服务，100+代理遍布全球。国际海运、空运、铁路多式联运联运等服务；</li>
              <li class="page5-box-li">增值服务，代买保险。优势线路：台湾、日本、韩国、东南亚、北美、欧洲</li>
            </ul>
          </el-card>

        </div>

        <div style="width: 100%;height: 28.3%;display: flex;justify-content: space-around">

          <img src="../assets/img-p6/tu1.png" style="width: 70%;height: 250%;margin-top: -300px">

        </div>

        <div style="width: 100%;height: 28.3%;display: flex;justify-content: space-around">

          <el-card class="page5-box" style="box-shadow: 0 0 20px #000">
            <p style="color: rgb(0,112,192);font-weight: bolder;font-size: 25px;text-align: center">香港本地派送服务</p>
            <ul>
              <li class="page5-box-li">提供香港进出口无缝、香港清关、出入口转口证（License）申请；</li>
              <li class="page5-box-li">代收代签、货代船公司转单等服务； </li>
              <li class="page5-box-li">香港本地拖柜、装柜、拆柜、提送、仓储转运等服务。</li>
            </ul>
          </el-card>

          <div class="page5-box">

          </div>

          <el-card class="page5-box" style="box-shadow: 0 0 20px #000">
            <p style="color: rgb(0,112,192);font-weight: bolder;font-size: 25px;text-align: center">国内物流服务</p>
            <ul>
              <li class="page5-box-li">拥有自营国内车队，提供珠三角，长三角配送服务；三方物流提供全国陆/空配送服务。</li>
            </ul>
          </el-card>

        </div>

        <div style="width: 100%;height: 15%;display: block;margin-top: 0">

          <div style="width: 100%;height: 80%;display: flex;justify-content: space-around;align-items: center">
            <img src="../assets/img-p6/dicang.png">
            <img src="../assets/img-p6/zhidan.png">
            <img src="../assets/img-p6/diaodu.png">
            <img src="../assets/img-p6/zhuizong.png">
            <img src="../assets/img-p6/jiesuan.png">
            <img src="../assets/img-p6/huoxian.png">
          </div>

          <div style="width: 100%;height: 20%;display: flex;justify-content: space-around;align-items: center;">
            <p>订舱</p>
            <p>制单</p>
            <p>调度</p>
            <p>追踪</p>
            <p>结算</p>
            <p>货险</p>
          </div>

        </div>
      </div>

      <div id="核心优势" class="content-div5">

        <div class="content-div5-card">
          <button @click="getAnchor('#核心优势')" type="button"><i class="el-icon-caret-top"></i></button>
          <div>核心优势</div>
        </div>

        <div class="content-div5-fu"></div>
        <LunBo></LunBo>
      </div>

      <div class="content-div6">
        <div>立即咨询成为合作伙伴</div>

        <router-link to="/about" style="color: black;text-decoration: none;margin-left: -20%"><button>点此咨询</button></router-link>
      </div>

      <div class="content-div7">
        <div style="width: 100%;height: 50%;display: flex;justify-content: space-evenly;align-items: center;">
          <img src="../assets/logo/1.png" class="content-div7-box">
          <img src="../assets/logo/2.png" class="content-div7-box">
          <img src="../assets/logo/3.png" class="content-div7-box">
          <img src="../assets/logo/4.png" class="content-div7-box">
          <img src="../assets/logo/5.png" class="content-div7-box">
        </div>

        <div style="width: 100%;height: 50%;display: flex;justify-content: space-evenly;align-items: center;">
          <img src="../assets/logo/6.png" class="content-div7-box">
          <img src="../assets/logo/7.png" class="content-div7-box">
          <img src="../assets/logo/8.png" class="content-div7-box">
          <img src="../assets/logo/9.png" class="content-div7-box">
          <img src="../assets/logo/10.png" class="content-div7-box">
        </div>



        <div class="cover"></div>
      </div>

      <div class="content-div8">
        <p style="font-size: 13px;color: rgb(157,141,105)">Copyright© 2023 上海芯捷通供应链管理有限公司 All Rights Reserved	   沪ICP备2022024846号-1</p>
        <p style="font-size: 13px;color: rgb(157,141,105);cursor: pointer" @click="drawer = true">加入我们</p>
      </div>

    </div>

    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <about></about>
    </el-drawer>
  </div>
</template>

<script>

import LunBo from '../components/HelloWorld.vue'
import pageLunBo from '../components/pageLunBo.vue'
import about from './AboutView.vue'

export default {
  props: {},
  components: {
    LunBo,
    pageLunBo,
    about
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    getAnchor(selector){
      this.$el.querySelector(selector).scrollIntoView({
        behavior:'smooth',
        block:'end'
      })
    },
  },
};
</script>

<style scoped>

*{
  margin: 0;
  padding: 0;
}

.app{
  height: 100%;
}

/* 内容区的样式 */
.content {
  background-color: white;
  width: 100%;
}
.content-div{
  width: 100%;
  height: 900px;
  font-size: 36px;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
}

.content-div1{
  width: 100%;
  height: 600px;
  font-size: 36px;
  background-color: #d3dce6;
}

.content-div2{
  width: 100%;
  height: 600px;
  font-size: 36px;
  background-color: #404359;
  display: flex;
  align-items: center;
}

.content-div3{
  width: 100%;
  height: 850px;
  font-size: 36px;
  background-color: #eaeaea;
  display: block;
  align-items: center;
}

.content-div4{
  width: 100%;
  height: 700px;
  font-size: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.content-div5{
  width: 100%;
  height: 900px;
  background-color: rgb(249,248,255);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content-div6{
  width: 100%;
  height: 200px;
  background-color: rgb(69,76,98);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.content-div6 div{
  top: 200px;
  font-size: 54px;
  color: #efefef;
  text-shadow: 0 0 3px #eaeaea;;
}

.content-div6 button{
  font-size: 18px;
  width: 225px;
  height: 75px;
  background: rgb(255,110,46);
  color: #efefef;
  font-weight: bolder;
  border-radius: 10px;
}


.content-div5-fu{
  width: 100%;
  height: 900px;

  background-image: url('@/assets/youshi/background.webp');

  background-size: cover;

  content: "";

  opacity: 0.5;

  position: absolute;

  /*z-index: -1;!*-1 可以当背景*!*/

  filter: blur(10px);
}

.content-div7{
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 0;
  padding: 0;
}

.content-div7-box{
  max-width: 7%;
  max-height: 100%;
  background: #1ac040;
}

.content-div8{
  width: 100%;
  height: 50px;
  background: rgb(34,34,34);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}



/* 导航栏的样式 */
.navs {
  position: fixed;
  top: 0px;
  margin-left: -10px;
  background-color: #fff;
  width: 100%;
  height: 7%;
  font-weight: bolder;
  z-index: 2;
  display: flex;
  align-items: center;
}

.navs ul li{
  float: left;
  margin-left: 30px;
  cursor: pointer;
  color: rgb(144,147,153);
  font-size: 25px;
}

.navs ul li:hover{
  text-shadow: 0 0 10px #f0b778;
}

.navs div:nth-child(1){
  width: 10%;
  height: 100%;
  margin-left: 12%;
}

.navs div:nth-child(2){
  width: 50%;
  height: 100%;
  margin-left: 3%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.navs div:nth-child(3){
  width: 10%;
  height: 100%;
  margin-left: 3%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.title{
  font-size: 20px;
}

/*page2*/
.page2-box{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.page2-tu{
  border-radius: 15px;
  width: 15%;
  height: 80%;
}

.page2-content{
  width: 50%;
  height: 80%;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(0,77,144)
}

ul li{
  font-size: 23px;
  color: #efefef;
  list-style: none;
}

.content-div1-card{
  width: 100px;
  height: 200px;
  background: #1ac040;
  position: absolute;
  top: 853px;
  left: 30%;
  border-radius: 15px;
  display: block;
  box-shadow: 0px 0px 10px 10px #e0e0e0;
}

.content-div1-card button{
  width: 100%;
  height: 30%;
  font-size: 50px;
  cursor: pointer;
  border: 0px;
  border-radius: 15px 15px 0 0;
}

.content-div1-card div{
  width: 100%;
  height: 70%;
  background: repeating-linear-gradient(to bottom, #3498db, #eee);
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  text-shadow: 0 0 5px #fff;
}


/*page3*/
.li{
  width: 90%;
  height: 80px;
  margin-top: 50px;
}

.p3-title-ziti{
  font-size: 23px;
  font-weight: bolder;
  color: #f9f8ff;
  margin-top: -20px;
}

p{
  color: white;
  font-size: 15px;
}

.content-div2-card{
  width: 150px;
  height: 100px;
  background: #eee;
  position: absolute;
  top: 1455px;
  left: 63%;
  border-radius: 15px;
  display: block;
  box-shadow: 0px 0px 10px 10px #e0e0e0;
}

.content-div2-card button{
  width: 100%;
  height: 30%;
  font-size: 50px;
  cursor: pointer;
  border: 0px;
  border-radius: 15px 15px 0 0;
}

.content-div2-card div{
  width: 100%;
  height: 70%;
  background: repeating-linear-gradient(to bottom, #3498db, #eee);
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  text-shadow: 0 0 5px #fff;
}

/*page4*/
.page4-div{
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: end;
}

.page4-div-1{
  width: 85%;
  height: 90%;
  display: flex;
  justify-content: space-around;
  align-items: end;
}

.page4-div-fu{
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page4-div-card{
  width: 80%;
  height: 85%;
  box-shadow: #42b983;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
}

.page4-div-card-all{
  width: 400px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 5px 5px 3px #000000;
  font-size: 40px;
  color: white;
  font-weight: bolder;
}

.page4-div-card-all-1{
  background: rgb(221,96,96);
}

.page4-div-card-all-2{
  background: rgb(99,179,199);
  margin-top: 30px;
}

.page4-div-card-all-3{
  background: rgb(140,168,115);
  margin-top: 30px;
}

.page4-div-card-all-4{
  background: rgb(255,204,51);
  margin-top: 30px;
}

.content-div3-card{
  width: 100px;
  height: 200px;
  background: #1ac040;
  position: absolute;
  top: 2050px;
  left: 56%;
  border-radius: 15px;
  display: block;
  box-shadow: 0px 0px 10px 10px #e0e0e0;
}

.content-div3-card button{
  width: 100%;
  height: 30%;
  font-size: 50px;
  cursor: pointer;
  border: 0px;
  border-radius: 15px 15px 0 0;
}

.content-div3-card div{
  width: 100%;
  height: 70%;
  background: repeating-linear-gradient(to bottom, #3498db, #eee);
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  text-shadow: 0 0 5px #fff;
}

/*page5*/
.page5-box{
  width: 33%;
  height: 100%;
  border-radius: 30px;
}

.page5-box-li{
  color: black;
}

.content-div3 div:last-child p{
  color: black;
  font-size: 23px;
  font-weight: bolder;
}

.content-div4-card{
  width: 150px;
  height: 120px;
  background: #eaeaea;
  position: absolute;
  top: 2753px;
  left: 50%;
  border-radius: 15px;
  display: block;
  box-shadow: 0px 0px 10px 10px #e0e0e0;
}

.content-div4-card button{
  width: 100%;
  height: 40%;
  font-size: 50px;
  cursor: pointer;
  border: 0px;
  border-radius: 15px 15px 0 0;
}

.content-div4-card div{
  width: 100%;
  height: 70%;
  background: repeating-linear-gradient(to bottom, #3498db, #eee);
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  text-shadow: 0 0 5px #fff;
}

/*page6*/
.content-div5-card{
  width: 170px;
  height: 110px;
  background: #eaeaea;
  position: absolute;
  top: 3610px;
  left: 45%;
  border-radius: 15px;
  display: block;
  box-shadow: 0px 0px 10px 10px #e0e0e0;
}

.content-div5-card button{
  width: 100%;
  height: 40%;
  font-size: 50px;
  cursor: pointer;
  border: 0px;
  border-radius: 15px 15px 0 0;
}

.content-div5-card div{
  width: 100%;
  height: 80%;
  background: repeating-linear-gradient(to bottom, #eee, #3498db);
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  text-align: center;
  font-weight: bolder;
  text-shadow: 0 0 5px #fff;
  font-size: 38px;
}

/*page7*/
.cover{
  position: absolute;
  left: 0;
  top: 4758px;
  width: 99%;
  height: 200px;
  opacity: .5;
  background-color: #000;
  z-index: 9;
  display: block;
  margin: 0 0 0 9px;
}
</style>

