<template>
  <div id="app">
    <router-view/>

<!--    回到顶部-->
    <el-backtop style="z-index: 999" :visibility-height="800">
      <div class="backtop">
        <i class="el-icon-upload2"></i>
      </div>
    </el-backtop>

    <div class="card">
      <div style="width: 100px;height: 33.3%;padding: 6px;">
        <img src="./assets/cebianlan/erweima.jpg" style="width: 100%;height: 100%">
      </div>

      <div style="width: 100px;height: 33.3%;padding: 6px;">
        <el-popover
            placement="left" trigger="hover" title="咨询热线：" content="181-1707-4580" z-index="99">
          <el-button type="text"  slot="reference" style="width: 100%;height: 100%;">
            <img src="./assets/cebianlan/tel.png" style="margin-top: 10px">
            <p style="font-weight: bolder;font-size: 20px;color: #f0b778">咨询热线</p>
          </el-button>
        </el-popover>
      </div>

      <div style="width: 100px;height: 33.3%;padding-left: 6px">
<!--        <img src="./assets/cebianlan/xinxi.png" style="padding: 35px">-->
        <el-button type="text" style="width: 100%;height: 100%" autofocus="true" @click="drawer = true">
          <img src="./assets/cebianlan/xinxi.png" style="">
          <p style="font-weight: bolder;font-size: 20px;color: #f0b778">消息咨询</p>
        </el-button>
      </div>
    </div>

    <div class="card1" id="card1">
      <i class="el-icon-close" style="margin-left: 96%;margin-top: 2px;cursor: pointer" @click="clickcard"></i>

      <div class="card1-one">
        <img src="./assets/logo/kefu.png">
      </div>

      <div class="card1-two">

        <div class="card1-two-1">
          <h3>致力于新一代仓储模式</h3>
          <h5>咨询热线：181-1707-4580</h5>
        </div>

        <div class="card1-two-2">
          <button class="card1-two-2-button1" @click="drawer = true">加入合作</button>
          <button class="card1-two-2-button2">在线咨询</button>
        </div>

      </div>

    </div>

    <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
      <about></about>
    </el-drawer>
  </div>
</template>

<script>
import about from './views/AboutView.vue'

export default {
  components: {
    about
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods:{
    clickcard(){
      document.getElementById('card1').style.display = 'none'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.card{
  width: 112px;
  height: 350px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(95, 95, 130, 0.2) 0px 2px 20px 0px;
  border-radius: 12px;
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: 999;
}

.card1{
  width: 500px;
  height: 200px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(95, 95, 130, 0.2) 0px 2px 20px 0px;
  border-radius: 12px;
  position: fixed;
  top: 85%;
  left: 20px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: 999;
  display: block;
}

.card1-one{
  width: 35%;
  height: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  float: left;
}

.card1-two{
  width: 65%;
  height: 100%;
  margin-top: -20px;
  display: block;
  float: left;
}

.card1-two-1{
  width: 100%;
  height: 50%;
}

.card1-two-2{
  width: 100%;
  height: 50%;
}

.card1-two-2-button1{
  width: 130px;
  height: 40px;
  background: rgb(255,127,54);
  border-radius: 5px;
  border: 0px;
  box-shadow: 0 0 15px #f0b778;
  font-size: 20px;
  font-weight: bolder;
  color: #efefef;
  cursor: pointer;
  float: left;
}

.card1-two-2-button2{
  margin-left: 20px;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(255,127,54);
  box-shadow: 0 0 15px #f0b778;
  font-size: 20px;
  font-weight: bolder;
  color: rgb(255,127,54);
  cursor: pointer;
}

.backtop{
  height: 100%;
  width: 100%;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0,0,0, .12);
  text-align: center;
  line-height: 40px;
  color: #000;
  font-size: 28px;
  border-radius: 5px;
  font-weight: bolder;
}

</style>
